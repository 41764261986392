'use client';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useState, useEffect } from 'react';
import { SyntheticEvent } from 'react';

import style from './baseTabs.module.scss';

interface Options {
    label: number | string;
    value: number | string;
}

interface SwitchProps {
    value: number | string | boolean;
    options: Options[];
    onChange: (value: number | string) => void;
    outline?: boolean;
    className?: string;
}

function BaseTabs({ value, options, onChange, className }: SwitchProps) {
    const [tabsWidth, setTabsWidth] = useState<string | null>(null);

    useEffect(() => {
        setTabsWidth(`calc(100% / ${options.length})`);
    }, [options.length]);

    return (
        <Tabs
            className={`${style.baseTab} ${className}`}
            onChange={(e: SyntheticEvent, changeValue: number | string) => {
                onChange(changeValue);
            }}
            value={value}
            variant="fullWidth"
            sx={{
                '& .MuiTabs-indicator': {
                    width: tabsWidth || 'auto'
                }
            }}
        >
            {options.length > 0 &&
                options.map(option => (
                    <Tab
                        className={style.baseTabBtn}
                        disableRipple
                        key={option.value}
                        label={option.label}
                        value={option.value}
                    />
                ))}
        </Tabs>
    );
}

export default BaseTabs;
