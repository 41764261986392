import Image from 'next/image';

import noDataImage from './img/noData.png';

import style from './noData.module.scss';

function NoData({ title }: { title: string }) {
    return (
        <div className={style.noData}>
            <Image src={noDataImage} width={202} height={146} alt="no data" />
            <div className={style.title}>{title}</div>
        </div>
    );
}

export default NoData;
