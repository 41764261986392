'use client';
import { CurationListType, ExploreListType } from '@/types/home';

import CurationList from '../components/curationList/curationList';
import ExploreList from '../components/exploreList/exploreList';

import style from './tagList.module.scss';

function PopularList({
    latestList,
    recommendList,
    exploreList,
    trendingList,
    loadMoreList
}: {
    latestList: CurationListType;
    recommendList: CurationListType;
    exploreList: ExploreListType;
    trendingList: CurationListType;
    loadMoreList: (page: number) => Promise<{ isEnd: boolean }>;
}) {
    return (
        <div className={style.tagContainer}>
            <CurationList dataList={latestList} title={'最新動態'} href={'/curation/latest'} />
            <CurationList
                dataList={recommendList}
                title={'精選推薦'}
                href={'/curation/recommend'}
            />
            <ExploreList
                title="探索"
                dataList={exploreList}
                loadMoreList={loadMoreList}
                thirdSlot={
                    <CurationList
                        dataList={trendingList}
                        title={'熱門主題'}
                        href={'/curation/trending'}
                    />
                }
            />
        </div>
    );
}

export default PopularList;
