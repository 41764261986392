'use client';
import NoData from '@/app/(home)/components/noData/noData';
import { CurationListType, ExploreListType } from '@/types/home';

import CurationList from '../components/curationList/curationList';
import ExploreList from '../components/exploreList/exploreList';

import style from './tagList.module.scss';

function FollowList({
    myrelevantList,
    exploreList,
    loadMoreList
}: {
    myrelevantList: CurationListType;
    exploreList: ExploreListType | CurationListType;
    loadMoreList: (page: number) => Promise<{ isEnd: boolean }>;
}) {
    if (myrelevantList.length === 0) {
        return <NoData title={'你沒有任何正在關注中的小壞壞喔'} />;
    }
    return (
        <div className={style.tagContainer}>
            <CurationList
                dataList={myrelevantList}
                title={'我的小壞壞'}
                href={'/curation/myrelevant'}
            />
            <ExploreList dataList={exploreList} loadMoreList={loadMoreList} defaultPage={1} />
        </div>
    );
}

export default FollowList;
