'use client';

import Skeleton from '@mui/material/Skeleton';
import Image from 'next/image';
import Link from 'next/link';
import { memo, useEffect, useState } from 'react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { GetBannerResponse } from '@/api/shop/shop';
import { useBaseStore } from '@/store/base';
import { useDepositStore } from '@/store/deposit';
import { useUserStore } from '@/store/user';

import style from './baseBanner.module.scss';

function SwiperBanner({ bannerList }: { bannerList: GetBannerResponse[] }) {
    const displayBanner = bannerList.filter((banner: GetBannerResponse) => banner.enabled);
    const setDepositPopup = useDepositStore.use.setDepositPopup();
    const isLogin = useUserStore.use.isLogin();
    const setLoginPopup = useBaseStore.use.setLoginPopup();

    return (
        <Swiper
            autoplay={{ delay: 6000, disableOnInteraction: false }}
            centeredSlides
            loop
            modules={[Pagination, Autoplay]}
            pagination={{ clickable: true }}
            slidesPerView={1.2}
            spaceBetween={16}
        >
            {displayBanner.map((banner: GetBannerResponse, idx: number) => (
                <SwiperSlide key={`${banner.seq}_${idx}`} className={style.slide}>
                    {banner?.action === 'recharge' ? (
                        <div
                            className={style.banner}
                            onClick={setDepositPopup}
                            suppressHydrationWarning
                        >
                            <Image
                                alt=""
                                height={30}
                                priority
                                src={banner.img_url}
                                suppressHydrationWarning
                                width={200}
                            />
                        </div>
                    ) : (
                        <>
                            {' '}
                            {isLogin || banner?.action !== 'login' ? (
                                <Link
                                    className={style.banner}
                                    href={banner.target_url || ''}
                                    suppressHydrationWarning
                                >
                                    <Image
                                        alt=""
                                        height={30}
                                        priority
                                        src={banner.img_url}
                                        suppressHydrationWarning
                                        width={200}
                                    />
                                </Link>
                            ) : (
                                <div
                                    className={style.banner}
                                    onClick={() => setLoginPopup()}
                                    suppressHydrationWarning
                                >
                                    <Image
                                        alt=""
                                        height={30}
                                        priority
                                        src={banner.img_url}
                                        suppressHydrationWarning
                                        width={200}
                                    />
                                </div>
                            )}
                        </>
                    )}
                </SwiperSlide>
            ))}
        </Swiper>
    );
}

interface BaseBannerProps {
    className?: string;
    bannerList: GetBannerResponse[];
}

function BaseBanner({ className, bannerList, ...props }: BaseBannerProps) {
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        setMounted(true);
    }, []);

    return (
        <>
            {!mounted ? (
                <div className={style.skeleton}>
                    <Skeleton
                        className={style.animate}
                        sx={{ bgcolor: '#222222' }}
                        style={{ marginBottom: 6 }}
                    />
                </div>
            ) : (
                <div className={`${style.baseBanner} ${className}`} {...props}>
                    {bannerList.length ? <SwiperBanner bannerList={bannerList} /> : null}
                </div>
            )}
        </>
    );
}

export default memo(BaseBanner);
