'use client';

import { useState, useEffect } from 'react';

import { getExploreList, getRisingstarsList, getMyrelevantList } from '@/api/couple/home';
import { getBanner } from '@/api/shop/shop';
import { GetBannerResponse } from '@/api/shop/shop';
import BaseBanner from '@/components/baseBanner/baseBanner';
import BaseTabs from '@/components/baseTabs/baseTabs';
import { ExploreListType, CurationListType } from '@/types/home';

import InfoFooter from './components/infoFooter/infoFooter';
import FollowList from './tagList/followList';
import NewcomerList from './tagList/newcomerList';
import PopularList from './tagList/popularList';

import style from './home.module.scss';

function HomePage({
    initLatestList,
    initRecommendList,
    initTrendingList,
    initExploreList
}: {
    initLatestList: CurationListType;
    initRecommendList: CurationListType;
    initTrendingList: CurationListType;
    initExploreList: ExploreListType;
}) {
    const tabOption = [
        { label: '熱門', value: 0 },
        { label: '新人', value: 1 },
        { label: '關注', value: 2 }
    ];
    const [tabValue, setTabValue] = useState(0);
    const [bannerList, setBannerList] = useState<GetBannerResponse[]>([]);

    const [isMounted, setIsMounted] = useState(false);
    useEffect(() => {
        setIsMounted(true);
    }, []);

    const [exploreList, setExploreList] = useState<ExploreListType>(initExploreList || []);

    const fetchPopularExploreList = async (page: number) => {
        const res = await getExploreList({ page, type: 'hot' });
        if (res.status !== 200) return { isEnd: true }; // TODO: ERROR HANDLE

        const newbieData = res.data;
        if (newbieData.length > 0) {
            setExploreList(list => [...list, ...newbieData]);
            return { isEnd: false };
        } else {
            return { isEnd: true };
        }
    };

    const [risingstarsList, setRisingstarsList] = useState<CurationListType>([]);
    const [newcomerExploreList, setNewcomerExploreList] = useState<ExploreListType>([]);

    const fetchRisingstarsList = async (page: number, mode: string) => {
        const res = await getRisingstarsList({ page, limit: 20, mode });
        if (res.status !== 200) return { isEnd: true }; // TODO: ERROR HANDLE

        const newbieData = res.data;
        if (newbieData.length > 0) {
            setRisingstarsList(list => [...list, ...newbieData]);
            return { isEnd: false };
        } else {
            return { isEnd: true };
        }
    };

    const fetchRisingstarsExploreList = async (page: number) => {
        const res = await getExploreList({ page, type: 'new' });
        if (res.status !== 200) return { isEnd: true }; // TODO: ERROR HANDLE

        const newbieData = res.data;
        if (newbieData.length > 0) {
            setNewcomerExploreList(list => [...list, ...newbieData]);
            return { isEnd: false };
        } else {
            return { isEnd: true };
        }
    };

    const [myrelevantList, setMyrelevantList] = useState<CurationListType>([]);
    const [myrelevantExploreList, setMyrelevantExploreList] = useState<ExploreListType>([]);

    const fetchMyrelevantList = async (page: number, mode: string) => {
        const res = await getMyrelevantList({ page, limit: 20, mode });
        if (res.status !== 200) return { isEnd: true }; // TODO: ERROR HANDLE

        const newbieData = res.data;
        if (newbieData.length > 0) {
            setMyrelevantList(list => [...list, ...newbieData]);
            return { isEnd: false };
        } else {
            return { isEnd: true };
        }
    };

    const fetchMyrelevantsExploreList = async (page: number) => {
        const res = await getExploreList({ page, type: 'follow' });
        if (res.status !== 200) return { isEnd: true }; // TODO: ERROR HANDLE

        const newbieData = res.data;
        if (newbieData.length > 0) {
            setMyrelevantExploreList(list => [...list, ...newbieData]);
            return { isEnd: false };
        } else {
            return { isEnd: true };
        }
    };

    useEffect(() => {
        if (isMounted && tabValue === 2) {
            fetchMyrelevantList(1, 'curation');
            fetchMyrelevantsExploreList(1);
        }
    }, [tabValue, isMounted]);

    useEffect(() => {
        if (isMounted) {
            fetchRisingstarsList(1, 'curation');
            fetchRisingstarsExploreList(1);
            fetchBanner();
        }
    }, [isMounted]);

    const fetchBanner = async () => {
        const localeList = ['tw', 'cn', 'en', 'es', 'pt', 'vn'];
        const subdomain = location.host.split('.')[0] || 'www';
        const countryCode = localeList.includes(subdomain) ? subdomain : '';

        const res = await getBanner(countryCode);

        if (res.status === 200) {
            setBannerList(res.data.result);
        }
    };

    return (
        <div className={style.homePage}>
            <BaseBanner bannerList={bannerList} />
            <BaseTabs
                className={style.tabBar}
                options={tabOption}
                value={tabValue}
                onChange={value => setTabValue(value as number)}
            />
            <div className={style.homeContent}>
                {tabValue === 0 && (
                    <PopularList
                        trendingList={initTrendingList}
                        latestList={initLatestList}
                        recommendList={initRecommendList}
                        exploreList={exploreList}
                        loadMoreList={fetchPopularExploreList}
                    />
                )}

                {tabValue === 1 && (
                    <NewcomerList
                        risingstarsList={risingstarsList}
                        exploreList={newcomerExploreList}
                        loadMoreList={fetchRisingstarsExploreList}
                    />
                )}

                {tabValue === 2 && (
                    <FollowList
                        myrelevantList={myrelevantList}
                        exploreList={myrelevantExploreList}
                        loadMoreList={fetchMyrelevantsExploreList}
                    />
                )}
            </div>
            <InfoFooter />
        </div>
    );
}

export default HomePage;
