'use client';
import { CurationListType, ExploreListType } from '@/types/home';

import CurationList from '../components/curationList/curationList';
import ExploreList from '../components/exploreList/exploreList';

import style from './tagList.module.scss';

function NewcomerList({
    risingstarsList,
    exploreList,
    loadMoreList
}: {
    risingstarsList: CurationListType;
    exploreList: ExploreListType;
    loadMoreList: (page: number) => Promise<{ isEnd: boolean }>;
}) {
    return (
        <div className={style.tagContainer}>
            <CurationList
                dataList={risingstarsList}
                title={'新人推薦'}
                href={'/curation/risingstars'}
            />
            <ExploreList
                title="探索"
                dataList={exploreList}
                loadMoreList={loadMoreList}
                defaultPage={1}
            />
        </div>
    );
}

export default NewcomerList;
